import styled from "styled-components";

const StyledWerkInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    font-size: 14px;
  }
`;

const StyledLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledLineItem = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
`;

const WerkInfo = ({ groessen }) => (
  <StyledWerkInfo>
    {groessen.map((item, index) => (
      <StyledLine key={index}>
        <StyledLineItem>
          <b>Größe:</b>
          {item.groesse}
        </StyledLineItem>
        {/* <StyledLineItem>
          <b>Preis</b>
          {item.preis}
        </StyledLineItem> */}
        <StyledLineItem>
          <b>Material:</b>
          {item.material}
        </StyledLineItem>
      </StyledLine>
    ))}
  </StyledWerkInfo>
);

export default WerkInfo;
